import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import { v4 as uuid } from 'uuid';
import { requiredRule, requiredRuleByKey } from '@/vue-app/utils/form-rules';

// Application
import { SearchPeriodicityOfContributionsQuery }
  from '@/modules/my-investment/catalogs/allianz/periodicity-of-contribution/application/queries/';
import {
  SearchOnBoardingStepsQuery,
} from '@/modules/my-investment/on-boarding-steps/application/queries';
import {
  SearchInvestmentProvidersQuery,
} from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';

// Domain
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';
import {
  PeriodicityOfContributionEntity,
} from '@/modules/my-investment/catalogs/allianz/periodicity-of-contribution/domain/entities/periodicity-of-contribution-entity';

export default class ContractSavingsContributionsRecurringContributionsViewModel {
  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_PERIODICITY_OF_CONTRIBUTION_QUERY)
  readonly search_periodicity_of_contributions_query!: SearchPeriodicityOfContributionsQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly search_on_boarding_steps_query!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly search_investment_providers_query!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly create_on_boarding_step_command!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly update_on_boarding_step_command!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.contract-savings.contributions.recurring-contributions';

  investment_provider_name = 'allianz';

  exists_step = false;

  step_name = 'recurring_contributions';

  investment_provider_id = '';

  is_loading = true;

  small_screen: null | boolean = null;

  AVAILABLE_DAYS = Array.from({ length: 28 }, (_, i) => i + 1);

  inputs = {
    periodicity: {
      idPeriod: '1',
      descPeriod: 'MENSUAL',
    },
    amount: '50000',
    payment_date: '',
  };

  form_validity = false;

  rules = {
    periodicity: [(value: string) => requiredRuleByKey(value, 'idPeriod')],
    amount: [
      requiredRule,
      (value: string) => (
        parseFloat(value.replace(/[^0-9.]/g, '')) >= 500
        || i18n.tc('utils.form-rules.minimum_error', 0, { value: '$500.00 MXN' }).toString()
      ),
      (value: string) => (
        parseFloat(value.replace(/[^0-9.]/g, '')) <= 50000
        || i18n.tc('utils.form-rules.maximum_error', 0, { value: '$50,000.00 MXN' }).toString()
      ),
    ],
    payment_date: [requiredRule],
  };

  on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {
      periodicity: {
        idPeriod: '1',
        descPeriod: 'MENSUAL',
      },
      monthly_collection_day: 0,
      recurring_contribution_amount: 0,
    },
  };

  periodicity_of_contributions_items: PeriodicityOfContributionEntity[] = [];

  get periodicity_of_contributions() {
    return this.search_periodicity_of_contributions_query.execute();
  }

  get investment_providers() {
    return this.search_investment_providers_query.execute();
  }

  get steps() {
    return this.search_on_boarding_steps_query.execute(this.investment_provider_id);
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      this.setInputsDataFromStep();
    }
  };

  setInputsDataFromStep = () => {
    this.inputs.periodicity = {
      idPeriod: this.on_boarding_step.payload.periodicity.idPeriod,
      descPeriod: this.on_boarding_step.payload.periodicity.descPeriod,
    };
    this.inputs.amount = this.on_boarding_step.payload.recurring_contribution_amount.toString();
    this.inputs.payment_date = this.on_boarding_step.payload.monthly_collection_day;
  };

  async saveStep() {
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.on_boarding_step.payload.monthly_collection_day = this.inputs.payment_date;
      this.on_boarding_step.payload.recurring_contribution_amount = parseFloat(this.inputs.amount.replace(/[^0-9.]/g, '')).toFixed(2);
      this.on_boarding_step.payload.periodicity = {
        idPeriod: this.inputs.periodicity.idPeriod,
        descPeriod: this.inputs.periodicity.descPeriod,
      };
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.update_on_boarding_step_command.execute(this.on_boarding_step);
      } else {
        await this.create_on_boarding_step_command.execute(this.on_boarding_step);
      }
    } catch (error) {
      this.message_notifier.showErrorNotification(
        'Ha ocurrido un error al guardar tu información, inténtalo nuevamente',
      );
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  initialize = async () => {
    const investment_providers = await this.investment_providers;
    this.setInvestmentProviderId(investment_providers);

    const steps = await this.steps;
    await this.verifyStep(steps);

    this.periodicity_of_contributions_items = await this.periodicity_of_contributions;

    this.is_loading = false;
  }
}
