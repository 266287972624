





































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import ContractSavingsContributionsRecurringContributionsViewModel
  from '@/vue-app/view-models/components/contract-savings/contributions/contract-savings-contributions-recurring-contributions-view-model';
import CustomAutocomplete from '@/vue-app/components/CustomAutocomplete.vue';

@Component({
  name: 'ContractSavingsContributionsRecurringContributions',
  components: {
    CustomAutocomplete,
  },
})
export default class ContractSavingsContributionsRecurringContributions extends Vue {
  recurring_contributions_view_model = Vue.observable(
    new ContractSavingsContributionsRecurringContributionsViewModel(),
  );

  @Watch('recurring_contributions_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  prevStep() {
    this.$emit('prevStep');
  }

  async nextStep() {
    const saved_step = await this.recurring_contributions_view_model.saveStep();
    if (saved_step) {
      this.$emit('nextStep');
    }
  }

  async created() {
    this.recurring_contributions_view_model.small_screen = getScreenSizeVariant() === 'small';
    await this.recurring_contributions_view_model.initialize();
  }
}

